import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const CarolineCrouchley = () => (
    <Layout title="Alumni Spotlight - Caroline Crouchley" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
        <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Caroline Crouchley</li>
        </ul>
        <Row>
        <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Caroline Crouchley</h1>
            <h3 className="alumni__profile-type">
                2019 3M Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Since participating in the 2019 Challenge, Caroline has been researching new drip irrigation methods to grow plants more efficiently and conserve water. Caroline is excited to be part of the 3M Young Scientist Challenge Alumni Network!
            </h4>
            <h2 className="alumni__profile-question">
                Remind us about your 3M Young Scientist Challenge Innovation Project? Did you continue to work on your innovation after you presented it during the Final Event at 3M? 
            </h2>
            <p>
                My 3M Young Scientist innovation project in 2019 was developing a high-speed magnetic train technology using Autodesk Inventor CAD software. I designed and developed a new train idea that could use renewable energy sources instead of fossil fuels to help the environment. Developing environmentally friendly and safe modes of transportation is becoming a growing issue due to the estimated rising population growth and risks of climate change. I learned that one of the biggest disadvantages of Maglev trains is their expense. This technology requires tearing down existing train tracks and replacing them with brand-new infrastructure. Elon Musk’s Hyperloop proposal risks spontaneous decompression, resulting in dangerous travel, and its costs are also extremely expensive. I wondered if I could develop an idea similar to Maglev and Hyperloop at a much lower cost. I continue to think of further ideas as new technologies continue to emerge. My prototype for a magnetic train provides a new idea for an eco-friendly, safe, and less expensive approach to high-speed train travel.
            </p>
            <h2 className="alumni__profile-question">
                We know you have been doing some exciting research on plants and agriculture. Can you tell us more about your research? What inspired you to research in this specific area? 
            </h2>
            <p>
                Current agriculture practices are being scrutinized to address the issues of water scarcity and rising demand for food as sustainability initiatives become more necessary. The intent of my research was to investigate new drip irrigation methods to grow plants more efficiently and conserve water. Additionally, I have been investigating Cellpose, a new deep-learning segmentation method that can widely segment cells from a variety of image types in my plant cell research. Approximately 70% of the world’s freshwater resources are consumed for agricultural production, and improving irrigation water use efficiency can lead to the conservation of limited water resources while achieving optimum economic crop productivity. According to the Food and Agriculture Organization (FAO), the world population will increase by 7.3 billion to 9.7 billion by 2050. The demand for agriculture will continue to grow while resources such as water are projected to be in limited supply. Drip irrigation is the gold standard in the farming industry, especially when compared to water sprinkler systems and overhead spraying. My interest and research in plant biology, hydrotropism, water conservation, and food security in developing nations inspired my project.
            </p>
            <h2 className="alumni__profile-question">
              What is your favorite memory from the 3M Young Scientist Challenge?
            </h2>
            <p>
              My favorite memory from the 3M Young Scientist Challenge was visiting the 3M Innovation Center, which gave me a real-world perspective. I was able to see a variety of working laboratories and talk to scientists about how their interests in high school led them to the field of research they are passionate about.
            </p>
            <h2 className="alumni__profile-question">
              How has the 3M Young Scientist Challenge inspired or led you to the path that you’re on today?
            </h2>
            <p>
              The 3M Young Scientist Challenge inspired me to believe in the power of my ideas! My experience provided guidance and mentorship from 3M scientists to develop my innovations further. Participating in the 3M Young Scientist Challenge was not only fun; it taught me the tools necessary to follow through with my magnetic train concept idea from beginning to end. It provided a one-in-a-lifetime opportunity to share it with others around the world. This mentorship gave me the inspiration to continue my scientific endeavors and led to my current interest in plant science and agricultural research. In addition, it helped me raise awareness about important environmental issues.
            </p>
            <h2 className="alumni__profile-question">
              Currently, what about the 3MYSC Alumni Network most excites you?
            </h2>
            <p>
              The 3MYSC Alumni Network celebrates the collaboration that makes scientific innovation possible! Bringing together a group of talented and motivated individuals who are interested in working together to solve problems we face on a global scale is truly inspiring. The alumni network focuses on connecting renowned scientists and professionals with young students to provide the mentorship necessary to uplift a new generation of critical thinkers. I am grateful for the friendships, guidance, and mentorship the 3MYSC provided me.
                        </p>
                        <h2 className="alumni__profile-question">
              Anything else you’d like to share?
                        </h2>
                        <p>
Scientific innovation starts with the courage to think of new ideas and share them with others in the hopes of new breakthroughs in the world of science. Thank you to the 3MYSC for letting students know that their ideas are important.
                        </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Crouchley-Caroline-2024-Alumni-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “The 3M Young Scientist Challenge inspired me to believe in the power of my ideas!”
                </h4>
                <h3>Caroline Crouchley</h3>
                <p>2019 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
                <Row>
                    <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Herbst-Hannah-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2015 Finalist</span>
                <h3>Hannah Herbst</h3>
                <p>
                  After the 3M Young Scientist Challenge, Hannah continued to work on her project, BEACON, an ocean energy collection and conversion device. Hannah attended Florida Atlantic University and Stanford University, and is now an entrepreneur working on some incredible projects!
                </p>
                <Link to="hannah-herbst" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
                    </Column>
                    <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Willis-Devin-2024-Alumni-Card.png" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Devin Willis</h3>
                <p>
                  Devin’s 3M Young Scientist Challenge project was a device, SlideMap, designed to expedite and enhance the precision of cancer diagnosis. He is currently studying electrical engineering at the University of Florida.
                </p>
                <Link to="devin-willis" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>

        </Row>
      </Container>
    </Section>
  </Layout>
);

export default CarolineCrouchley;
